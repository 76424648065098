export const Endpoints = {
  Account: {
    Profile: "auth/profile",
    ChangePassword: "auth/ChangePassword",
    UpdatePassword: "auth/UpdatePassword",
    UpdateProfile: "auth/UpdateProfile",
  },
  Conditions: {
    main: "conditions",
    lookup: "conditions/lookup",
  },
  Countries: {
    main: "countries",
    lookup: "countries/lookup",
  },
  Transactions: {
    main: "transactions",
  },
  Categories: {
    main: "categories",
    lookup: "categories/lookup",
  },
  Cities: {
    main: "cities",
    lookup: "cities/lookup",
  },
  Roles: {
    main: "groups",
    lookup: "groups/lookup",
    addPermission: (id: string) => `groups/${id}/permissions`,
    permissions: (id: string) => `groups/${id}/permissions`,
    deletePermission: (id: string, permissionId: string) =>
      `groups/${id}/permissions/${permissionId}`,
  },
  Results: {
    main: "results",
    lookup: "results/lookup",

  },
  Permission: {
    main: "permissions",
    lookup: "permissions/lookup",
  },
  Employee: {
    main: "users",
    lookup: "users/lookup",
    documents: "employees/documents",
    accounts: "employees/accounts",
    roles: (id: string) => `users/${id}/groups`,
    createAccount: (id: string) => `employees/${id}/account`,
    addToRole: (id: string) => `users/${id}/groups`,
    removeFromRole: (id: string, roleId: string) =>
      `settings/employees/${id}/groups/${roleId}`,
    job: (id: string) => `employees/${id}/job`,
    createPassword: (id: string) => `settings/employees/setpassword/${id}`,
    activate: (id: string) => `users/${id}/activate`,
    revoke: (id: string) => `settings/employees/${id}/revoke`,
    deactivate: (id: string) => `users/${id}/deactivate`,
    changePassword: (id: string) => `settings/employees/${id}/changePassword`,
    deleteAddress: (id: string, addressId: string) =>
      `employees/${id}/Address/${addressId}`,
    deleteAccount: (id: string, accountId: string) =>
      `employees/${id}/Account/${accountId}`,
  },
  Tasks: {
    main: "settings/tasks",
    lookup: "settings/tasks/lookup",
    pending: "settings/tasks?status=1",
  },
  Dashboards: {
    main: "settings/dashboard",
    numberOfTasks: "settings/dashboard/numberoftasks",
    numberOfTaskLineItems: "settings/dashboard/numberoftasklineitems",
    numberOfCompanies: "settings/dashboard/numberofcompanies",
    numberOfUsers: "settings/dashboard/numberofusers",
    numberOfCompletedTasks: "settings/dashboard/numberoftaskcompleted",
    numberOfPendingTasks: "settings/dashboard/numberoftaskpending",
  },
  Customers:{
    main: "customers",
    lookup: "customers/lookup",
    entries: (id: string) => `customers/${id}/entries`,
    contacts: (id: string) => `customers/${id}/contacts`,
    results: (id: string) => `customers/${id}/results`,
    documents: (id: string) => `customers/${id}/documents`,
    deleteContact: (id: string, contactId: string) => `customers/${id}/contacts/${contactId}`,
    deleteDocument: (id: string, documentId: string) => `customers/${id}/documents/${documentId}`,
    deleteResult: (id: string, resultId: string) => `customers/${id}/results/${resultId}`,
    deleteEntry: (id: string, entryId: string) => `customers/${id}/entries/${entryId}`,
    expiredDocuments: `customers/documents/expired`,

    

  },
  DocumentTypes: {
    main: "documentTypes",
    lookup: "documentTypes/lookup",
  },
};
