import { useState } from "react";
import {
    ModalForm,
    ProFormText,
    ProFormDatePicker,
    ProFormSelect,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import Error from "components/ui/error";
import { ICombo, ILookupKeys, ISubmitionResponse } from "components/api";
import { dateTimeFormats } from "assets";
import { Button, Upload, UploadFile } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { UploadProps } from "antd/lib";

export type CustomerDocumentRequest = {
    id: 0;
    documentTypeId: number;
    dateOfIssue: Date;
    dateOfExpiry: Date;
    file: File | null;
};

type FormProps = {
    visible: boolean;
    onClose: (visible: boolean) => void;
    lookups: Record<ILookupKeys, ICombo[]>;
    onFinish: (values: CustomerDocumentRequest) => Promise<ISubmitionResponse>;
    title: string;
    defaultValue: CustomerDocumentRequest;

};

const CustomerDocumentForm = (props: FormProps) => {
    const { visible, onClose, onFinish } = props;
    const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
        error: null,
        isError: false,
        isSuccess: false,
    });

    const [file, setFile] = useState<File | null>(null);


    const resetErrors = () => {
        if (formErrors.isError) {
            setFormErrors({
                error: null,
                isError: false,
                isSuccess: true,
            });
        }
    };

    const uploadProps: UploadProps = {
        beforeUpload: (file: File) => {
            setFile(file);
            return false;
        },
    }

    return (
        <ModalForm<CustomerDocumentRequest>
            title={props.title}
            size={"middle"}
            width={400}
            visible={visible}
            autoFocusFirstInput
            request={async () => props.defaultValue}
            name="galawo-model-form"
            modalProps={{
                onCancel: () => onClose(false),
                destroyOnClose: true,
                closable: false,
                maskClosable: false,
            }}
            onFinish={async (values) => {

                var formValues = { ...values, file: file };
                var result = await onFinish(formValues);
                setFormErrors(result);
                if (result.isSuccess) {
                    message.success("Successfuly completed");
                    onClose(false);
                }
                return result.isSuccess;
            }}
            onChange={resetErrors}
            submitter={{
                searchConfig: {
                    resetText: "Cancel",
                    submitText: "Submit",
                },
            }}
        >
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>Select File</Button>
            </Upload>

            <ProFormSelect
                name="documentTypeId"
                label="Document Type"
                placeholder="Select a document type"
                rules={[{ required: true }]}
                request={async () => props.lookups.documentTypes ?? []}
                debounceTime={0}
                showSearch={true}
                width="xl"

            />
            <ProFormDatePicker
                name="dateOfIssue"
                label="Date of Issue"
                rules={[{ required: true }]}
                width="xl"
                fieldProps={{
                    format: dateTimeFormats.dateOnly,
                }}
            />

            <ProFormDatePicker
                name="dateOfExpiry"
                label="Date of Expiry"
                rules={[{ required: true }]}
                width="xl"
                fieldProps={{
                    format: dateTimeFormats.dateOnly,
                }}
            />


            <Error error={formErrors.error} isError={formErrors.isError} />
        </ModalForm>
    );
};

export default CustomerDocumentForm;
