import { useState } from "react";
import { useLocation } from "react-router-dom";
import message from "antd/lib/message";

import * as api from "components/api";
import { ProfileHeader } from "./header";
import { ResponseType } from "pages/employees";
import DetailsTab from "./tabs";
import { PopUpActions } from "pages/common";
import { ILookup } from "components/api";
import ActionsForm from "./actions-form";
import RolesForm from "./roles-form";
export type MenuActions =
  | "gPass"
  | "roles"
  | "activate"
  | "deactivate"
  | "changePass"
  | "revoke";

export type JobType = {
  salary: number;
  startDate: Date;
  endDate: Date;
  job: ILookup;
  type: ILookup;
};
const lookupQueries: api.ILookupQueryProps[] = [
  {
    endpoint: "sex",
    fromLookup: false,
    isLocalEnum: true,
    id: "sex",
  },

  {
    endpoint: api.Endpoints.Roles.lookup,
    fromLookup: false,
    isLocalEnum: false,
    id: "roles",
  },
];
type PopUpType = {
  id: number | string;
  title: string;
  visible: boolean;
  type: "activate" | "deactivate" | "revoke";
};
const Index = () => {
  const params = new URLSearchParams(useLocation().search);
  const endpoint = api.Endpoints.Employee.main;
  const employeeId = params.get("id");
  const [actionForm, setActionForm] = useState({
    visible: false,
    type: "gPass",
  });
  const [roleForm, setRoleForm] = useState({
    visible: false,
    type: "roles",
    payload: {
      groupId: "",
    },
  });
  const [ecForm, setECForm] = useState({
    visible: false,
    payload: {
      id: 0,
      firstName: "",
      lastName: "",
      address: "",
      email: "",
      phone: "",
      gender: 0,
      active: true,
      humanRelationId: 0,
      employeeId: "",
    },
    type: "add",
  });


  const [overtimeForm, setOvertimeForm] = useState({
    visible: false,
    payload: {
      id: 0,
      notes: "",
      date: new Date(),
      totalHours: 0,
      overTimeTypeId: 0,
      employeeId,
    },
    type: "add",
  });


  const [showPopup, setShowPopup] = useState<PopUpType>({
    id: "",
    title: "",
    visible: false,
    type: "activate",
  });
  const options = api.useLookups({ lookups: lookupQueries });
  const mutations = api.useMutator<
    | any
  >();
  const { isLoading, data, mainQueryKey } =
    api.useFetchSingleItem<ResponseType>({
      endpoint,
      ids: [employeeId ?? ""],
      enabled: employeeId !== null,
    });
  const job = api.useFetchSingleItem<JobType>({
    endpoint: api.Endpoints.Employee.job(employeeId!),
    ids: null,
    enabled: employeeId !== null,
  });
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isLoading || !data) {
    return <div>Loading...</div>;
  }
  const handlerMenuClick = ({ key }: { key: MenuActions }) => {
    switch (key) {
      case "roles":
        console.log('Hello roles')
        setRoleForm({
          visible: true,
          type: "roles",
          payload: { groupId: "" },
        });
        break;
      case "gPass":
        setActionForm({ visible: true, type: "gPass" });
        break;
      case "changePass":
        setActionForm({ visible: true, type: "changePass" });
        break;
      case "activate":
        setShowPopup({
          visible: true,
          id: "",
          title: "Are you sure to activate this account",
          type: "activate",
        });
        break;
      case "deactivate":
        setShowPopup({
          visible: true,
          id: "",
          title: "Are you sure to deactivate this account",
          type: "deactivate",
        });
        break;
      case "revoke":
        setShowPopup({
          visible: true,
          id: "",
          title:
            "Are you sure to revoke all active logins related this account",
          type: "revoke",
        });
        break;
      default:
        throw new Error("Clicked On uknown menu button");
    }
  };
  const submit = async (
    payload: any

  ): Promise<api.ISubmitionResponse> => {
    try {
      if (actionForm.visible && actionForm.type === "gPass") {
        const dest = api.Endpoints.Employee.createPassword(employeeId!);
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [mainQueryKey],
          payload: {
            ...payload,
          },
        });
      } else if (actionForm.visible && actionForm.type === "changePass") {
        const dest = api.Endpoints.Employee.createPassword(employeeId!);

        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [api.Endpoints.Employee.createPassword],
          payload: {
            ...payload,
          },
        });
      } else if (ecForm.visible) {
        const dest = api.Endpoints.Employee.createPassword(employeeId!);

        if (ecForm.type === "add") {
          return await mutations.addHandler({
            endpoint: dest,
            mainQueryKey: [`${dest}?employeeId=${employeeId!}`],
            payload: {
              ...payload,
              employeeId,
            },
          });
        } else {
          return await mutations.editHandler({
            endpoint: `${dest}/${ecForm.payload.id}`,
            mainQueryKey: [`${dest}?employeeId=${employeeId!}`],
            payload: {
              ...payload,
              employeeId,
            },
          });
        }
      } else if (roleForm.visible) {
        const dest = api.Endpoints.Employee.addToRole(employeeId!);
        const mqk = [`${api.Endpoints.Employee.roles(employeeId!)}`, null];
        console.log('mqk', mqk);
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: mqk,
          payload: {
            ...payload,
            employeeId,
          },
        });
      } else {
        const dest = "";
        return await mutations.addHandler({
          endpoint: dest,
          mainQueryKey: [dest],
          payload,
        });
      }
    } catch (error) {
      return {
        isError: true,
        isSuccess: false,
        error: error as api.IErrorResult,
      };
    } finally {
      mutations.invalidateQuery(mainQueryKey);
    }
  };
  const confirmPopup = async () => {
    try {
      if (showPopup.visible) {
        switch (showPopup.type) {
          case "activate": {
            await mutations.editHandler({
              endpoint: api.Endpoints.Employee.activate(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "deactivate": {
            await mutations.editHandler({
              endpoint: api.Endpoints.Employee.deactivate(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfully completed");
            break;
          }
          case "revoke": {
            await mutations.addHandler({
              endpoint: api.Endpoints.Employee.revoke(employeeId!),
              mainQueryKey: mainQueryKey,
            });
            message.success("Successfuly completed");
            break;
          }
          default:
            break;
        }
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    } finally {
      setShowPopup({ ...showPopup, visible: false });
    }
  };
  const deleteHandler = async (id: number | string, type: MenuActions) => {
    try {
      switch (type) {

        case "roles": {
          const dest = api.Endpoints.Employee.removeFromRole(
            employeeId!,
            id.toString()
          );
          await mutations.deleteHandler({
            endpoint: dest,
            mainQueryKey: [`${api.Endpoints.Employee.roles(employeeId!)}`, null],
          });
          message.success("Successfuly completed");
          //refresh the data
          break;
        }
        default:
          break;
      }
    } catch (error) {
      const err = error as api.IErrorResult;
      message.error(err.detail ?? err.title ?? "Error");
    }
  };
  const editHandler = (payload: any, type: MenuActions) => {
    switch (type) {
      default:
        break;
    }
  };
  return (
    <>
      <ProfileHeader
        title="Employee Profile"
        data={data.data}
        jobTitle={job.data?.data.job}
        options={options.data}
        handler={handlerMenuClick}
      />
      <DetailsTab
        employeeId={employeeId}
        lookups={{
          data: options.data,
          isloading: isLoading,
        }}
        deleteHandler={deleteHandler}
        editHandler={editHandler}
        data={data.data}
        job={{
          data: job.data?.data,
          isError: job.isError,
        }}
      />
      <ActionsForm
        lookups={options.data}
        visible={actionForm.visible}
        title={"Generate Employee Login"}
        onFinish={submit}
        onClose={() => setActionForm({ visible: false, type: "gPass" })}
      />

      <RolesForm
        lookups={options.data}
        visible={roleForm.visible}
        title={"Roles"}
        onFinish={submit}
        defaultValue={roleForm.payload}
        onClose={() => setRoleForm((e) => ({ ...e, visible: false }))}
      />

      <PopUpActions
        closable={false}
        title={showPopup.title}
        id={showPopup.id}
        visible={showPopup.visible}
        width={340}
        visibilityChangeFunc={() =>
          setShowPopup({ ...showPopup, visible: false })
        }
        onConfirm={confirmPopup}
      />
    </>
  );
};

export default Index;
