import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import tableExport from "antd-table-export";
import { Authorize } from "components/auth";
import { useNavigate } from "react-router-dom";
import ViewButton from "@ant-design/icons/EyeOutlined";


const Table = (props: TableProps) => {
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const navigate = useNavigate();
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [

        {
          title: "Document Type",
          dataIndex: "documentType",
          request: async () => lookups["documentTypes"],
          render: (_, { documentType }) => documentType.name,
          order: 1,
        },
        {
          title: "Customer",
          dataIndex: "customer",
          request: async () => lookups["customers"],
          render: (_, { customer }) => {
            return (
              lookups.customers.find(({ value }) => value === customer.id)
                ?.label ?? customer.name
            );
          },
          order: 1,
        },
        {
          title: "Date Of Issue",
          dataIndex: "dateOfIssue",
          valueType: "date",
          hideInSearch: true,
        },
        {
          title: "Date Of Expiry",
          dataIndex: "dateOfExpiry",
          hideInSearch: true,
          valueType: "date",
        },
        {
          title: "Created By",
          dataIndex: "createdBy",
          hideInSearch: true,
        },
        {
          title: "Status",
          dataIndex: "status",
          request: (record) => record.status,
        },
        {
          title: "Created On",
          dataIndex: "createdOn",
          valueType: "date",
          hideInSearch: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({
                      pathname: "/customers/details",
                      search: "id=" + record.customer.id,
                    })
                  }
                  type="default"
                  icon={<ViewButton />}
                  title="Details"
                />

              </Space>
            );
          },
        },
      ];
    },
    [props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isloading);

  const exportInstance = new tableExport(dataSource.map(v => ({
    ...v,
  })),
    renderedColumns.filter(x => x.key !== 'options').map(x => ({
      dataIndex: x.dataIndex as string,
      title: x.title! as string
    }))
  );


  return (
    <ProTable<ResponseType>
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button type="primary" onClick={() => exportInstance.download("Accounts List", "xlsx")}>
          Export
        </Button>
      ]}
    />
  );
};

export default Table;
