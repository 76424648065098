import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Button from "antd/es/button";
import Space from "antd/es/space";
import Popconfirm from "antd/es/popconfirm";

import { ResponseType, TableProps } from "./types";
import { ICombo, ILookupKeys } from "components/api";
import tableExport from "antd-table-export";
import { Authorize } from "components/auth";
import { useNavigate } from "react-router-dom";
import ViewButton from "@ant-design/icons/EyeOutlined";


const Table = (props: TableProps) => {
  const { isLoading, total, pageSize, dataSource, SendRequestForData } = props;
  const navigate = useNavigate();
  const columns = useCallback(
    (
      lookups: Record<ILookupKeys, ICombo[]>,
      isLoading: boolean
    ): ProColumns<ResponseType>[] => {
      if (isLoading) {
        return [];
      }
      return [

        {
          title: "Condition",
          dataIndex: "condition",
          request: async () => lookups["conditions"],
          render: (_, { condition }) => condition.name,
          order: 1,
        },
        {
          title: "Customer",
          dataIndex: "customer",
          request: async () => lookups["customers"],
          render: (_, { customer }) => {
            return (
              lookups.customers.find(({ value }) => value === customer.id)
                ?.label ?? customer.name
            );
          },
          order: 1,
        },

        {
          title: "Result Type",
          dataIndex: "resultType",
          request: async () => lookups["resultTypes"],
          render: (_, { resultType }) => resultType.name,
          order: 1,
        },
        {
          title: "Result Date",
          dataIndex: "resultDate",
          valueType: "date",
          hideInSearch: true,
        },
        {
          title: "Issuing Authority",
          dataIndex: "issuingAuthority",
          hideInSearch: true,
        },
        {
          title: "Reference Number",
          dataIndex: "referenceNumber",
          hideInSearch: true,
        },
        {
          title: "Operations",
          width: 200,
          key: "options",
          valueType: "option",
          render: (_, record) => {
            return (
              <Space size="middle">
                <Button
                  onClick={() =>
                    navigate({
                      pathname: "/customers/details",
                      search: "id=" + record.customer.id,
                    })
                  }
                  type="default"
                  icon={<ViewButton />}
                  title="Details"
                />
                {
                  Authorize({ claim: 'DR' }) &&
                  <Popconfirm
                    title={`Are you sure to  delete "${record.customer.name ?? "this"
                      }" from the system?`}
                    onConfirm={() => props.deleteHandler(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button danger type="primary">
                      Delete
                    </Button>
                  </Popconfirm>
                }
                {
                  Authorize({ claim: 'UR' }) &&
                  <Button
                    onClick={() => props.editHandler(record)}
                    type="primary"
                  >
                    Edit
                  </Button>
                }

              </Space>
            );
          },
        },
      ];
    },
    [props]
  );
  const renderedColumns = columns(props.lookups.data, props.lookups.isloading);

  const exportInstance = new tableExport(dataSource.map(v => ({
    ...v,
  })),
    renderedColumns.filter(x => x.key !== 'options').map(x => ({
      dataIndex: x.dataIndex as string,
      title: x.title! as string
    }))
  );

  const addButton = Authorize({ claim: 'CR' }) && <Button type="primary" onClick={props.addNewHandler}>Add New</Button>;

  return (
    <ProTable<ResponseType>
      columns={renderedColumns}
      loading={isLoading}
      request={async (params = {}, sort, filter) =>
        SendRequestForData({ params, sort, filter })
      }
      onReset={() => {
        let params = { current: 1, pageSize };
        let sort = {};
        let filter = {};
        SendRequestForData({ params, sort, filter, isReset: true });
      }}
      dataSource={dataSource}
      rowKey="id"
      pagination={{
        showQuickJumper: true,
        pageSize,
        total,
      }}
      search={{ labelWidth: "auto" }}
      dateFormatter="string"
      toolBarRender={() => [
        <Button type="primary" onClick={() => exportInstance.download("Accounts List", "xlsx")}>
          Export
        </Button>,
        addButton
      ]}
    />
  );
};

export default Table;
