
import Tag from "antd/es/tag";
import Row from "antd/es/row";
import Space from "antd/es/space";
import Descriptions from "antd/es/descriptions";


import { ResponseType } from "pages/employees";
import * as api from "components/api";
import { ILookup } from "components/api";
import { PageHeader } from "@ant-design/pro-layout";
import { ProCard } from '@ant-design/pro-components';
import ActionMenu from "./action_menu";

type profileHeaderProps = {
  data: ResponseType;
  jobTitle: ILookup | undefined;
  options: Record<api.ILookupKeys, api.ICombo[]>;
  title: string;
  handler: (event: any) => void;
};

export const ProfileHeader = ({
  data,
  title,
  options,
  jobTitle,
  handler,
}: profileHeaderProps) => {
  return (
    <PageHeader
      onBack={() => window.history.back()}
      title={title}
      subTitle={`${data.name} ${data.name}`}
      tags={
        <Space>
          <Tag color={data.active ? "green" : "volcano"}>
            Active: {data.active ? "Yes" : "No"}
          </Tag>

        </Space>
      }
      extra={
        <Space>
          <ActionMenu
            handleClick={handler}
            active={data.active}
            canAccess={true}
          />
        </Space>
      }
    >

      <ProCard
        title="User Detail"
        split={'vertical'}

        headerBordered
      >
        <ProCard colSpan="50%">
          <Row>
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="First Name">
                {data.name}
              </Descriptions.Item>
              <Descriptions.Item label="Last Name">
                {""}
              </Descriptions.Item>
              <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
              <Descriptions.Item label="Phone">{data.phone}</Descriptions.Item>

            </Descriptions>
          </Row>
        </ProCard>

        <ProCard >
          <Row>
            <Descriptions size="default" column={1}>
              <Descriptions.Item label="Has User">
                {"Yes"}
              </Descriptions.Item>
              <Descriptions.Item label="CreatedBy">{data.createdBy}</Descriptions.Item>
              <Descriptions.Item label="CreatedOn">{data.createdOn.toString()}</Descriptions.Item>
            </Descriptions>
          </Row>
        </ProCard>
      </ProCard>

    </PageHeader>
  );
};
