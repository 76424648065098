
import { PageContainer } from '@ant-design/pro-layout';
import { Card, Statistic, Col, Row, Flex } from 'antd';
import { Endpoints, ILookup, useFetchSingleItem, useLookups, useQuery } from 'components/api';
import { IChangeRequestProps } from 'pages/common';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { paginateEndpoint } from 'utils';
import DatePicker from 'antd/es/date-picker';

const { RangePicker } = DatePicker;

const endpoint = Endpoints.Tasks.main;

const data = [
    { year: '1991', value: 3 },
    { year: '1992', value: 4 },
    { year: '1993', value: 3.5 },
    { year: '1994', value: 5 },
    { year: '1995', value: 4.9 },
    { year: '1996', value: 6 },
    { year: '1997', value: 7 },
    { year: '1998', value: 9 },
    { year: '1999', value: 13 },
];
export type ResponseType = {
    id: string;
    responsibleManager: string;
    company: ILookup;
    department: ILookup;
    employee: ILookup;
    title: string;
    description: string;
    deliverables: string;
    priority: ILookup;
    date: string;
    targetCompletedDate: string;
    status: ILookup;
    active: boolean;
    percentage: number;
};

type StatisticResponseType = {
    group: string;
    value: number;
}

const Home = () => {
    const { pathname } = useLocation();
    const [locationData, setLocationData] = useState<string>("");
    const [dateRange, setDateRange] = useState<any>();
    var startDate = dateRange ? dateRange[0].format('YYYY-MM-DD') : '';
    var endDate = dateRange ? dateRange[1].format('YYYY-MM-DD') : '';
    const { data: totalTasks } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfTasks + `?startDate=${startDate}&endDate=${endDate}`,
            ids: null,
            enabled: 0 !== null,
        });

    const { data: totalTaskLineItems } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfTaskLineItems + `?startDate=${startDate}&endDate=${endDate}`,
            ids: null,
            enabled: 0 !== null,
        });

    const { data: totalCompanies } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfCompanies,
            ids: ["0"],
            enabled: 0 !== null,
        });

    const { data: totalUsers } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfUsers,
            ids: ["0"],
            enabled: 0 !== null,
        });
    const { data: totalCompletedTasks } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfCompletedTasks + `?startDate=${startDate}&endDate=${endDate}`,
            ids: null,
            enabled: 0 !== null,
        });

    const { data: totalPendingTasks } =
        useFetchSingleItem<StatisticResponseType>({
            endpoint: Endpoints.Dashboards.numberOfPendingTasks + `?startDate=${startDate}&endDate=${endDate}`,
            ids: null,
            enabled: 0 !== null,
        });


    const { data, isLoading, refetch, error, isError } = useQuery<
        ResponseType[]
    >({ endpoint, query: `&startDate=${startDate}&endDate=${endDate}` });
    const options = useLookups({
        lookups: [

            {
                endpoint: "priority",
                fromLookup: false,
                isLocalEnum: true,
                id: "priority",
            },
            {
                endpoint: "status",
                fromLookup: false,
                isLocalEnum: true,
                id: "status",
            },
        ],
        enabled: true,
    });
    const navigate = useNavigate();
    const config = {
        data,
        xField: 'year',
        yField: 'value',
        label: {
            position: 'middle',
            style: {
                fill: '#FFFFFF',
                opacity: 0.6,
            },
        },
    };
    const SendRequestForData = ({
        params,
        sort,
        filter,
        isReset,
    }: IChangeRequestProps) => {
        const newLogaction = paginateEndpoint({
            endpoint,
            includeEndpoint: false,
            requestParams: { params, sort, filter },
            dependent: undefined,
            outsiderDependent: false,
        });
        console.log("current", locationData);
        console.log("next", newLogaction);

        if (locationData !== newLogaction) {
            setLocationData(newLogaction);
            navigate({
                pathname: pathname,
                search: newLogaction,
            });
        }

        return data?.data ?? [];
    };
    return (
        <PageContainer>
            <Flex justify='end'>
                <RangePicker onChange={(values) => setDateRange(values)} />
            </Flex>
            <Row gutter={16} style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Col span={6}>
                    <Card title="Total Customers" bordered={false} onClick={() => navigate('tasks')}>
                        <Statistic title="Total Customers" value={'0'} precision={2} valueStyle={{ color: '#3f8600' }} suffix=""></Statistic>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Results" bordered={false} onClick={() => navigate('completed-tasks')}>
                        <Statistic title="Total Results" value={totalCompletedTasks?.data.value} precision={2} valueStyle={{ color: '#3f8600' }} suffix=""></Statistic>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Conditions" bordered={false} onClick={() => navigate('pending-tasks')}>
                        <Statistic title="Total Conditions" value={totalPendingTasks?.data.value} precision={2} valueStyle={{ color: '#3f8600' }} suffix=""></Statistic>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card title="Users" bordered={false}>
                        <Statistic title="Total Users" value={totalTaskLineItems?.data.value} precision={2} valueStyle={{ color: '#3f8600' }} suffix=""></Statistic>
                    </Card>
                </Col>


            </Row>

        </PageContainer>
    )
}

export default Home
