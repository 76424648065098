import { useEffect, useState } from "react";
import {
  DrawerForm,
  ProFormText,
  ProFormSelect,
  ProFormDatePicker,
} from "@ant-design/pro-form";
import message from "antd/es/message";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { RequestType, FormProps } from "./types";
import { Endpoints, ISubmitionResponse } from "components/api";
import { dateTimeFormats } from "assets";
import Error from "components/ui/error";
import DependentSelect from "components/ui/fields/dependent-select";
import React from "react";

const Form = (props: FormProps) => {
  const { state, title, visible, onVisibleChange, initialState, onFinish } =
    props;
  const [formErrors, setFormErrors] = useState<ISubmitionResponse>({
    error: null,
    isError: false,
    isSuccess: false,
  });

  const resetErrors = () => {
    if (formErrors.isError) {
      setFormErrors({
        error: null,
        isError: false,
        isSuccess: true,
      });
    }
  };

  return (
    <DrawerForm<RequestType>
      title={`${state} ${title}`}
      size={"middle"}
      style={{ top: 100 }}
      width={"30%"}
      visible={visible}
      onVisibleChange={onVisibleChange}
      autoFocusFirstInput
      request={async () => initialState}
      name="galawo-model-form"
      drawerProps={{
        onClose: () => onVisibleChange(false),
        destroyOnClose: true,
        closable: false,
        maskClosable: false,
      }}
      onFinish={async (values) => {
        console.log(values);
        var result = await onFinish({
          ...values
        });
        if (result.isSuccess) {
          message.success("Successfuly completed");
        }
        setFormErrors(result);
        return result.isSuccess;
      }}
      onChange={resetErrors}
      submitter={{
        searchConfig: {
          resetText: "Cancel",
          submitText: "Submit",
        },
      }}
    >

      <ProFormText
        name="name"
        label="Name"
        placeholder="Enter Name"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      <ProFormText
        name="phoneNumber"
        label="Phone Number"
        placeholder="Enter Phone Number"
        rules={[{ required: true, min: 2, max: 50, pattern: /^[0-9]+$/ }]}
        width="xl"
      />
      {/* <ProFormText
        name="email"
        label="Email"
        placeholder="Enter Email"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      /> */}

      {/* 
      <ProFormSelect
        name="cityId"
        label="City"
        rules={[{ required: true }]}
        width="xl"
        request={async () => props.lookups.data["cities"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select city"
      /> */}
      <ProFormSelect
        name="sex"
        label="Sex"
        rules={[{ required: true }]}
        width="xl"
        request={async () => props.lookups.data["sex"] ?? []}
        debounceTime={0}
        showSearch={true}
        placeholder="Please select sex"
      />


      {/* <ProFormDatePicker
        name="dateOfBirth"
        label="Date Of Birth"
        rules={[{ required: true, type: "date" }]}
        width="xl"
        fieldProps={{
          format: dateTimeFormats.dateOnly,
        }}
      /> */}
      <ProFormText
        name="passportNumber"
        label="Registration Number"
        placeholder="Enter Registration Number"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      <ProFormText
        name="countryOfOrigin"
        label="Country Of Origin"
        placeholder="Enter Country Of Origin"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />
      <ProFormText
        name="address"
        label="Address"
        placeholder="Enter Address"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      />


      {/* <ProFormText
        name="description"
        label="Description"
        placeholder="Enter Description"
        rules={[{ required: true, min: 2, max: 50 }]}
        width="xl"
      /> */}
      <Error error={formErrors.error} isError={formErrors.isError} />
    </DrawerForm>
  );
};

export default Form;
