import { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import type { MenuDataItem, ProSettings } from "@ant-design/pro-layout";
import ProLayout from "@ant-design/pro-layout";

import defaultProps from "./_defaultProps";
// import SettingsDrawer from "./Settings";
import RightContent from "./RightContent";
import Footer from "./Footer";
import "./layout.css";
import { ILayoutProps } from ".";
import { baseTitle } from "assets/";
import { AuthContext } from "components/auth";




const getModule = (path: string) => {
  if (path.length === 1) {

    return undefined;
  } else {
    const newPath = path.substring(1, path.length)?.indexOf("/");
    if (newPath <= -1) {
      return path.substring(0, path.length);
    }

    return path.substring(0, newPath + 1);
  }
};

function Layout({ ...props }: ILayoutProps) {
  const navigate = useNavigate();
  const { user, logoutHandler } = useContext(AuthContext);

  const { children } = props;
  const { pathname } = useLocation();

  const group = getModule(pathname);
  const routes = defaultProps(group && '' + group);


  const [settings] = useState<Partial<ProSettings> | undefined>({
    navTheme: "light",
    layout: "mix",
    contentWidth: "Fluid",
    fixedHeader: false,
    fixSiderbar: true,
    splitMenus: false,


  });

  const menuItemClick = (item: MenuDataItem) => navigate(item.path || "/");

  return (
    <div id="dashboard-layout">
      <ProLayout
        title={'Badbaado Portal'}
        {...routes}
        navTheme="realDark"
        location={{
          pathname,
        }}

        token={{
          header: {
            colorBgHeader: '#198b19',
            colorHeaderTitle: '#FFFFFF',
            colorTextMenu: '#FFFFFF',
            colorTextMenuSecondary: '#FFFFFF',
            colorTextMenuSelected: '#198b19',
            colorTextMenuActive: '#FFFFFF',
            colorTextRightActionsItem: '#FFFFFF',
            colorBgRightActionsItemHover: '#ffffff',

          },
        }}

        onMenuHeaderClick={(e: any) => console.log(e)}
        menuItemRender={(item: any, dom: any) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={() => menuItemClick(item)}>{dom}</a>
        )}

        rightContentRender={() => (
          <RightContent

            username={user?.username ?? user?.email ?? ""}
            logoutHandler={logoutHandler}
          />
        )}
        footerRender={() => <Footer />}
        {...settings}
      >
        {children}
      </ProLayout>
      {/* <SettingsDrawer
        settings={settings}
        setSetting={setSetting}
        pathname={pathname}
      /> */}
    </div>
  );
};

export default Layout;
