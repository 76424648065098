

import { Modal } from 'antd';
import { Image } from 'antd/lib';

type CustomerDocumentDetailProps = {
    file: string | null;
    fileType: string | null;
    visible: boolean;
    onClose: (visible: boolean) => void;
}

const CustomerDocumentDetail = (props: CustomerDocumentDetailProps) => {
    console.log(props.fileType);
    return (

        <Modal title="Document" open={props.visible} onCancel={() => props.onClose(false)} width={"50%"}   >
            {props.fileType === "application/pdf" ? <iframe title='Pdf Viewer' id="iframe" src={props.file!} width="900px" height="600px" scrolling="no" /> : <Image src={props.file!} alt="Document" style={{ width: '100%' }} />}

        </Modal>
    )
}

export default CustomerDocumentDetail