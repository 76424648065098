import Row from "antd/es/row";
import Descriptions from "antd/es/descriptions";
import { ResponseType } from "pages/employees";
import { JobType } from ".";
import { ProCard } from "@ant-design/pro-components";
type profileHeaderProps = {
  data: ResponseType;
  job: JobType | undefined;
  isError: boolean;
};

const PersonalIfno = ({ data, job, isError }: profileHeaderProps) => {
  return (
    <ProCard>
      <Row>
        <Descriptions size="small" column={3} title="Email">
          <Descriptions.Item label="Email">{data.email}</Descriptions.Item>
          <Descriptions.Item label="Full Name">
            {data.name}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" column={3} title="Info">
          <Descriptions.Item label="Phone">
            {data.phone}
          </Descriptions.Item>
          <Descriptions.Item label="Company Name">
            {""}
          </Descriptions.Item>
        </Descriptions>


        <Descriptions size="small" column={2} title="Residence">
          <Descriptions.Item label="Issue Date">
            {""}
          </Descriptions.Item>

        </Descriptions>
      </Row>
    </ProCard>
  );
};

export default PersonalIfno;
