import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Space from "antd/lib/space";
import Popconfirm from "antd/es/popconfirm";
import Button from "antd/es/button";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import EditIcon from "@ant-design/icons/EditOutlined";
import ViewButton from "@ant-design/icons/EyeOutlined";

import {
    ICombo,
    ILookupKeys,
    useFetchSingleItem,
    Endpoints,
    ILookup,
    useLookups,
} from "components/api";

import { CustomerDocumentRequest } from "./customer-document-form";
import { MenuActions } from "pages/tasks/details";
import { Authorize } from "components/auth";

type TableProps = {
    lookups: {
        data: Record<string, ICombo[]>;
        isloading: boolean;
    };
    customerId: string | null;
    deleteHandler: (id: number, type: MenuActions) => void;
    handlerMenuClick: (payload: CustomerDocumentRequest | ResponseType | string, type: MenuActions) => void;
};
type ResponseType = {
    id: number;
    dateOfIssue: string;
    dateOfExpiry: Date;
    documentType: ILookup;
    file: string;
    createdBy: Date;
};
const CustomerDocuments = (props: TableProps) => {
    const endpoint = Endpoints.Customers.documents(props.customerId!);
    const { data, isError } = useFetchSingleItem<ResponseType[]>({
        endpoint,
        enabled: true,
        ids: null,
    });

    const columns = useCallback(
        (
            lookups: Record<ILookupKeys, ICombo[]>,
            isLoading: boolean
        ): ProColumns<ResponseType>[] => {
            if (isLoading) {
                return [];
            }
            return [
                {
                    title: "Date Of Issue",
                    dataIndex: "dateOfIssue",
                },
                {
                    title: "Date Of Expiry",
                    dataIndex: "dateOfExpiry",
                },
                {
                    title: "Document Type",
                    dataIndex: "documentType",
                    render: (_, record) => record.documentType.name,
                },
                {
                    title: "Created By",
                    dataIndex: "createdBy",
                },
                {
                    title: "Created On",
                    dataIndex: "createdOn",
                },
                {
                    title: "Operations",
                    width: 200,
                    key: "options",
                    valueType: "option",
                    render: (_, record) => {
                        return (
                            <Space size="middle">
                                <Button
                                    onClick={() =>
                                        props.handlerMenuClick(record, "viewDocument")
                                    }
                                    type="default"
                                    icon={<ViewButton />}
                                    title="Details"
                                />
                                {Authorize({
                                    claim: "DCU",
                                }) && (
                                        <Popconfirm
                                            title={`Are you sure to delete the selected task from the system?`}
                                            onConfirm={() => props.deleteHandler(record.id, "deleteDocument")}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                danger
                                                type="primary"
                                                icon={<DeleteIcon />}
                                                title="Delete"
                                            />
                                        </Popconfirm>
                                    )}




                            </Space>
                        );
                    },
                },
            ];
        },
        [props]
    );
    if (isError || !data) {
        return <div>Error</div>;
    }
    const addButton = Authorize({ claim: 'CCU' }) ? <Button
        onClick={() => {
            props.handlerMenuClick(
                {
                    dateOfIssue: new Date(),
                    dateOfExpiry: new Date(),
                    documentTypeId: 0,
                    id: 0,
                    file: null,
                },
                "addDocument"
            );
        }}
        type="primary"
        icon={<EditIcon />}
        title="Add Document"

    >Add Document</Button> : null;

    return (
        <ProTable<ResponseType>
            headerTitle={addButton}
            columns={columns(props.lookups.data, props.lookups.isloading)}
            loading={false}
            request={async () => data?.data}
            dataSource={data?.data ?? []}
            rowKey="id"
            search={false}
            dateFormatter="string"
            pagination={false}
        />
    );
};

export default CustomerDocuments;
