import { useNavigate, useLocation } from "react-router-dom";

import {
  Endpoints,
  useQuery,
  useLookups,
} from "components/api";
import Header from "components/ui/header";
import { IChangeRequestProps } from "pages/common";
import { useState } from "react";
import { paginateEndpoint } from "utils";
import Table from "./table";
import { ResponseType } from "./types";

const endpoint = Endpoints.Customers.expiredDocuments;

const Condition = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();



  const [locationData, setLocationData] = useState<string>("");

  const { data, isLoading, refetch, error, isError, mainQueryKey } = useQuery<
    ResponseType[]
  >({ endpoint });

  const options = useLookups({
    lookups: [
      {
        endpoint: Endpoints.DocumentTypes.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "conditions",
      },
      {
        endpoint: Endpoints.Customers.lookup,
        fromLookup: false,
        isLocalEnum: false,
        id: "customers",
      },
    ],
    enabled: true,
  });




  const SendRequestForData = ({
    params,
    sort,
    filter,
    isReset,
  }: IChangeRequestProps) => {
    const newLogaction = paginateEndpoint({
      endpoint,
      includeEndpoint: false,
      requestParams: { params, sort, filter },
      dependent: undefined,
      outsiderDependent: false,
    });
    console.log("current", locationData);
    console.log("next", newLogaction);

    if (locationData !== newLogaction) {
      setLocationData(newLogaction);
      navigate({
        pathname: pathname,
        search: newLogaction,
      });
    }

    return data?.data ?? [];
  };

  // if (error || isError) {
  //   return <div>Error</div>;
  // }

  return (
    <>
      <Header
        title="Expired Documents"
        error={error?.detail ?? ""}
        isError={isError}
        isLoading={isLoading}
        refetch={refetch}
      />
      <Table
        SendRequestForData={SendRequestForData}
        dataSource={data?.data ?? []}
        deleteHandler={() => { }}
        isLoading={isLoading}
        pageSize={data?.size ?? 10}
        total={data?.count ?? 0}
        lookups={{
          data: options.data,
          isloading: options.isLoading,
        }}
      />
    </>
  );
};

export default Condition;
