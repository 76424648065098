import { useCallback } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import Space from "antd/lib/space";
import Popconfirm from "antd/es/popconfirm";
import Button from "antd/es/button";
import DeleteIcon from "@ant-design/icons/DeleteOutlined";
import EditIcon from "@ant-design/icons/EditOutlined";
import {
    ICombo,
    ILookupKeys,
    useFetchSingleItem,
    Endpoints,
    ILookup,
} from "components/api";

import { CustomerEntryRequest } from "./customer-entry-form";
import { MenuActions } from "pages/tasks/details";
import { Authorize } from "components/auth";

type TableProps = {
    lookups: {
        data: Record<string, ICombo[]>;
        isloading: boolean;
    };
    customerId: string | null;
    deleteHandler: (id: number, type: MenuActions) => void;
    handlerMenuClick: (payload: CustomerEntryRequest, type: MenuActions) => void;
};
type ResponseType = {
    id: number;
    condition: ILookup;
    resultType: ILookup;
    customer: ILookup;
    resultDate: Date;
    issuingAuthority: string;
    referenceNumber: string;
};
const CustomerResults = (props: TableProps) => {
    const endpoint = Endpoints.Customers.results(props.customerId!);
    const { data, isError } = useFetchSingleItem<ResponseType[]>({
        endpoint,
        enabled: true,
        ids: null,
    });

    const columns = useCallback(
        (
            lookups: Record<ILookupKeys, ICombo[]>,
            isLoading: boolean
        ): ProColumns<ResponseType>[] => {
            if (isLoading) {
                return [];
            }
            return [
                {
                    title: "Condition",
                    dataIndex: "condition",
                    render: (_, record) => record.condition.name,
                },
                {
                    title: "Result Type",
                    dataIndex: "resultType",
                    render: (_, record) => record.resultType.name,
                },
                {
                    title: "Result Date",
                    dataIndex: "resultDate",
                },
                {
                    title: "Issuing Authority",
                    dataIndex: "issuingAuthority",
                },
                {
                    title: "Reference Number",
                    dataIndex: "referenceNumber",
                },
                {
                    title: "Operations",
                    width: 200,
                    key: "options",
                    valueType: "option",
                    render: (_, record) => {
                        return (
                            <Space size="middle">
                                {Authorize({
                                    claim: "DCU",
                                }) && (
                                        <Popconfirm
                                            title={`Are you sure to delete the selected task from the system?`}
                                            onConfirm={() => props.deleteHandler(record.id, "delete")}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button
                                                danger
                                                type="primary"
                                                icon={<DeleteIcon />}
                                                title="Delete"
                                            />
                                        </Popconfirm>
                                    )}




                            </Space>
                        );
                    },
                },
            ];
        },
        [props]
    );
    if (isError || !data) {
        return <div>Error</div>;
    }


    return (
        <ProTable<ResponseType>
            columns={columns(props.lookups.data, props.lookups.isloading)}
            loading={false}
            request={async () => data?.data}
            dataSource={data?.data ?? []}
            rowKey="id"
            search={false}
            dateFormatter="string"
            pagination={false}
        />
    );
};

export default CustomerResults;
