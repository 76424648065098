// eslint-disable-next-line no-undef
const baseDomain = process.env.NODE_ENV === "production"? "https://api.badbaado.org" :"http://localhost:5241" 
export const baseTitle = process.env.REACT_APP_Title || "Badbaado Portal";
export const baseUrl = baseDomain + "/api/v1";
export const staleTime: number = 0;
export const tokenInStorageKey: string = "tokens";
export type AppColors =
  | "success"
  | "processing"
  | "error"
  | "default"
  | "warning"
  | "pink"
  | "red"
  | "yellow"
  | "orange"
  | "cyan"
  | "green"
  | "blue"
  | "purple"
  | "geekblue"
  | "magenta"
  | "volcano"
  | "gold"
  | "lime";
export const tokenRefreshleeway = {
  ms: 120000,
  sc: 120,
};

export const dateTimeFormats = {
  dateTime: "DD MMM, YYYY HH:mm:ss",
  onlyDate: "DD MMM, YYYY",
  dateOnly: "YYYY-MM-DD",
  onlyTime: "HH:mm:ss",
};

export const toAddIdSuffix = ["operator", "driver", "rider", "service"];
